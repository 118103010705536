import React from 'react';
import ActivateAccount from '../components/Pages/ActivateAccount';
import OnlyAnonymous from '@components/Auth/OnlyAnonymous';

const ActivateAccountPage: React.FC = () => (
  <OnlyAnonymous redirectTo={'/account/settings'}>
    <ActivateAccount />
  </OnlyAnonymous>
);

export default ActivateAccountPage;
