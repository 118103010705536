import { useMutation, gql } from '@apollo/client';
import {
  FinalizeAccountCreationMutationVariables,
  FinalizeAccountCreationMutation,
  FetchAccountInfoMutation,
  FetchAccountInfoMutationVariables,
} from '../../../types/operations';

export const accountInfoMutation = gql`
  mutation fetchAccountInfo($token: String!) {
    accountInfoByToken(token: $token) {
      accountId
      availableSecurityQuestions {
        id
        text
      }
    }
  }
`;

export const useFetchAccountInfo = () => {
  return useMutation<
    FetchAccountInfoMutation,
    FetchAccountInfoMutationVariables
  >(accountInfoMutation);
};

export const mutation = gql`
  mutation finalizeAccountCreation(
    $token: String!
    $accountId: String!
    $password: String!
    $question: String!
    $answer: String!
  ) {
    finalizeAccountCreation(
      token: $token
      accountId: $accountId
      password: $password
      question: $question
      answer: $answer
    )
  }
`;

export const useFinalizeAccountCreationMutation = () => {
  return useMutation<
    FinalizeAccountCreationMutation,
    FinalizeAccountCreationMutationVariables
  >(mutation);
};
