import React, { useEffect } from 'react';

import Loading from '@components/Loading';
import Title from '@components/Title';

import { OneColumn } from '../../Layouts/OneColumn';
import ActivateAccountForm, { FormValues } from './Form';
import PasswordInstructions from './PasswordInstructions';
import {
  useFetchAccountInfo,
  useFinalizeAccountCreationMutation,
} from './query';
import {
  selectMutationValues,
  selectSecurityQuestionOptions,
} from './selectors';

import { useNotifications } from '@use-cases/notifications';

import { getQueryParams } from '@utils/query-params';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

const ActivateAccount = () => {
  const { token } = getQueryParams();
  const [fetchAccountInfo, { data, loading, error }] = useFetchAccountInfo();
  useEffect(() => {
    if (typeof token === 'string') {
      fetchAccountInfo({ variables: { token } });
    }
  }, [token, fetchAccountInfo]);
  const { addSuccess } = useNotifications();
  const [finalizeAccountCreation] = useFinalizeAccountCreationMutation();
  const { t } = useTranslation();
  const { login } = useAppConfig();

  if (loading) {
    return <Loading />;
  }

  if (error || !data?.accountInfoByToken?.availableSecurityQuestions) {
    return <p>error</p>;
  }

  const { accountId } = data.accountInfoByToken;
  const securityQuestions = selectSecurityQuestionOptions(
    data.accountInfoByToken.availableSecurityQuestions
  );

  const handleSubmit = async (values: FormValues) => {
    if (typeof token !== 'string') {
      throw new Error('Token must be a string.');
    }
    const mutationValues = {
      ...selectMutationValues(values, securityQuestions),
      accountId,
      token,
    };
    await finalizeAccountCreation({ variables: mutationValues });
    await login();
    addSuccess(
      t(
        'activate-acount.form.success',
        'Success! Your account has been activated. Please sign in.'
      ),
      { id: 'form.success' }
    );
  };

  return (
    <OneColumn>
      <Title>{t('activate-account.title', 'Activate Account')}</Title>
      <p className="text-big">
        {t(
          'activate-account.subtitle',
          'To activate your account, please add a password and security question. '
        )}
      </p>
      <PasswordInstructions />
      <ActivateAccountForm
        handleSubmit={handleSubmit}
        securityQuestions={securityQuestions}
      />
    </OneColumn>
  );
};

export default ActivateAccount;
