import { FormValues, SecurityQuestions } from './Form';

export const selectSecurityQuestionOptions = (
  rawQuestions: Array<{ id: string; text: string }>
): SecurityQuestions =>
  rawQuestions.map(q => ({
    label: q.text,
    value: q.id,
  }));

export const selectMutationValues = (
  { password, question, answer }: FormValues,
  securityQuestions: SecurityQuestions
) => ({
  password,
  question: securityQuestions.filter(q => q.value === question)[0].label,
  answer,
});
