import React from 'react';

import { Form, Formik } from 'formik';
import { TFunction } from 'i18next';
import * as yup from 'yup';

import { Button } from '../../Button';
import Divider from '../../Divider';
import Select from '../../Formik/Select';
import TextField from '../../Formik/TextField';
import {
  hasMinLength,
  isRequired,
  schemaField,
} from '../../Formik/validation/schemaDefinitions';

import { useTranslation } from '@external/react-i18next';

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    password: schemaField(t, yup.string(), isRequired),
    confirmPassword: schemaField(t, yup.string(), isRequired).oneOf(
      [yup.ref('password'), null],
      t(
        'form.field.confirm_does_not_match_password',
        'Your passwords do not match'
      )
    ),
    question: schemaField(t, yup.string(), isRequired),
    answer: schemaField(t, yup.string(), hasMinLength(4), isRequired),
  });

export interface FormValues {
  password: string;
  confirmPassword: string;
  question: string;
  answer: string;
}

export type SecurityQuestions = Array<{
  label: string;
  value: string;
}>;

interface Props {
  securityQuestions: SecurityQuestions;
  handleSubmit: (values: FormValues) => void;
}
const ActivateAccountForm: React.FC<Props> = ({
  handleSubmit,
  securityQuestions,
}) => {
  const { t } = useTranslation();
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        password: '',
        confirmPassword: '',
        question: '',
        answer: '',
      }}
      validationSchema={validationSchema(t)}
    >
      {({ isSubmitting }) => {
        return (
          <Form className="mb-2">
            <TextField
              name="password"
              label={t('activate-account.form.password-label', 'Password')}
              required
              password
            />
            <TextField
              name="confirmPassword"
              label={t(
                'activate-account.form.confirmpassword-label',
                'Confirm Password'
              )}
              required
              password
            />
            <Select
              name="question"
              label={t(
                'activate-account.form.question-label',
                'Security Question'
              )}
              options={securityQuestions}
              required
            />
            <TextField
              name="answer"
              label={t('signup.form.answer-label', 'Answer')}
              required
            />
            <Divider />
            <p className="text-sm font-extralight">
              {t(
                'signpup.form.disclaimer',
                'By creating an account, you agree to the <a>Terms of Service</a> and acknowledge our <a>Privacy Policy</a>.'
              )}
            </p>
            <Button disabled={isSubmitting}>
              {t('signup.form.button-label', 'Continue')}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ActivateAccountForm;
